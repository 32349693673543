.career-sec{

	margin-bottom: 50px;
	.career-item{
		color: white;

		@media (--not_phone){
			&:first-child>.vc_column-inner{
				margin: 20px;
				margin-left: 0;
			}

			&:last-child>.vc_column-inner{
				margin: 20px;
				margin-right: 0;
			}
		}

		@media (--phone){
			margin-bottom: 20px;
		}
		&.item-full .vc_column-inner,
		&>.vc_column-inner{
			padding: 30px 40px;

			.wpb_text_column{
				margin-bottom: 0;
			}
		}

		ul{
			padding-left: 20px;

			li{
				font-size: 16px;
				font-family: 'font-r';
			}
		}
	}
}

.career-detail{
	max-width: 1100px;
    margin: 30px auto !important;
	&>.wpb_column:last-child{
		.wpb_text_column{
			margin-bottom: 10px;
		}
	}
}