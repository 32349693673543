.single {
	.post-navigation,
	.entry-thumb{
		display: none;
	}
}

.pdf a.vc_general {
    background: none;
    padding-left: 0;
    font-size: 16px;
    padding: 0;
}

.home-post.post-list .vc_grid-item-mini:hover:before{
	content: none;
}