/*#mainnav.leftright ul li {
    margin: 0 30px;
}*/

#masthead #mainnav ul li a:hover {
    font-weight: 700;
}
.header-clone{
	display: none;
}
#content .container.content-wrapper {
    padding-top: 0px;
}
p.footer-menu,
p.inspiren  {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}


mark.count {
    display: none;
}
.new-site-logo img.site-logo {
    margin-bottom: 0;
}

#masthead.site-header {
    /*background: linear-gradient(black, rgba(0,0,0,0));*/
    background: none;
}

@media only screen and (min-width: 767px){ 
nav#mainnav.leftright {
	/*height: 10px;*/
    display: flex;
    justify-content: center;
    align-items: center;
}
nav#mainnav.leftright>* {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
}

@media only screen and (min-width: 1025px){ 
.menu-menu-left-container {
    /*padding-left: 5%;*/
}
.header-wrap .col-md-4.col-sm-8.col-xs-12 /*icon*/{
    display: none;
}
#menu-menu-right,
#menu-menu-left{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

}

@media only screen and (max-width: 1023px){
nav#mainnav.leftright{
	display: none;
}
.new-site-logo {
    display: none;
}

}

