:root{
    --color-blue: #0392d9;
    --color-font: #6d6e71;
    /*--color-red: #fb755c;
    --color-yellow: #fff33e;
    --color-yellow-form: #f9e678;
    --color-shadow: #808080;
    --color-menu: rgba(0,0,0,0.6);
    --color-footer: rgba(0,0,0,0.3);*/
};

@font-face{
    font-family: 'font-b';
    src: url('https://project.inspiren.com.my/leanlee/wp-content/themes/sydney/assets/fonts/century-gothic-bold.ttf');
}
@font-face{
    font-family: 'font-r';
    src: url('https://project.inspiren.com.my/leanlee/wp-content/themes/sydney/assets/fonts/century-gothic-regular.TTF');
}