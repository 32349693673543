.contact-detail{
	td{
		border: none;
	    text-align: left;
	    font-size: 16px;
	    font-family: 'font-r';
	    padding: 2px;

	    @media (--phone){
	    	font-size: 14px;
	    	vertical-align: top;
	    }
	}
}

.contact-form.contact-contact{
	.contact-title{
		margin-bottom: 20px;
	}
}