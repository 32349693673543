   /* ####################################################################################

                                    HOME

/* ####################################################################################*/
.wpb_revslider_element.wpb_content_element {
    margin: 0;
    margin-bottom: 80px;
}
div#slide-1-layer-2 {
    font-family: 'font-b' !important;
}

.frame{
	figure.wpb_wrapper.vc_figure {
	    padding: 15px;
	    position: relative;

	    :before{
	    	content: '';
		    position: absolute;
		    width: 30%;
		    height: 25%;
		    background: var(--color-blue);
		    left: 0;
		    top: 0;
	    }
	    :after{
	    	content: '';
		    position: absolute;
		    width: 76%;
		    height: 51%;
		    background: var(--color-blue);
		    right: 0;
		    bottom: 0;
	    }

	    img{
	    	position: relative;
	    	z-index: 9;
	    }
	}

}

.home-about{

	@media (--not_phone){
		.vc_col-sm-6:last-child {
		    padding-left: 20px;
		}
	}
	.wpb_column:last-child .vc_column-inner>.wpb_wrapper{
		
    	text-align: justify;

    	@media (--not-phone){
			max-width: 585px;
			padding-left: 20px;
		}

    	p:first-child{
			font-family: font-b;
    	}
	}
	figure.wpb_wrapper.vc_figure{
		padding-bottom: 20px;
		position: relative;

		&:before{
			background: #0392d9;
		    content: '';
		    position: absolute;
		    width: 100%;
		    height: 75%;
		    left: -40px;
		    bottom: -20px;

		    @media (--phone){
		    	bottom: 0;
				}
		    }

		img{
			position: relative;
		}
	}
}

.custom-btn {
	a.vc_general {
	    padding: 5px 20px !important;
	    border: 1px solid;
	    font-size: 14px;
		font-family: 'font-r';
	}
}

h2.vc_custom_heading.custom-title{
	position: relative;
	padding: 10px 0;
	margin-bottom: 35px;
}

.home-icon{
	margin: 20px 0 40px;
	.wpb_wrapper{
		text-align: center;
	}

	.vc_inner.vc_row-fluid{
		@media (--not_phone){
			width: 80%;
			margin: auto;
		}
	}

	.wpb_single_image{
		display: inline-flex;
		width: 24.7%;

		@media (--not_phone){
			padding: 0 4%;
		}

		@media (--phone){
			width: 48%;
		}

		img{
			max-width: 120px;
		}
	}
		
		h2.wpb_singleimage_heading{
			margin-bottom: 0;
		    font-size: 16px;
		    margin-top: 0;
		    color: var(--color-blue);
		    font-family: 'font-r';
		}
	
}

.vc_row.home-cat{
	padding-bottom: 40px;

	h2.woocommerce-loop-category__title{
		text-transform: uppercase;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    color: white;
	}

	mark.count {
		background: none;
		display: block;
		font-size: 0;

		&:before{
			content: '';
		    height: 4px;
		    width: 80px;
		    position: absolute;
		    background: white;
		    left: 50%;
		    bottom: -10px;
		    transform: translate(-50%);
		}
	}
	.category-img-container{
		&:before{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: var(--color-blue);
			opacity: 0.7;
			left: 0;
			top: 0;
		}

		img{
			margin-bottom: 0!important;
		}
	}
	.woocommerce ul.products li.product{
		@media (--not_phone){
			margin: 0 1% 1% 0 !important;
			width: 24% !important;
		}

		&:hover{
			h2.woocommerce-loop-category__title,
			.category-img-container:before,
			mark.count:before{
				opacity: 0;
				transition: 0.3s opacity ease-out;
			}
		}
	}
}

.home-brand{
	.brand-img{
		padding-top: 60px;
		margin-bottom: 60px;

		&:before{
			content: '';
			display: block;
		    position: absolute;
		    width: 26vw;
		    height: 40px;
		    background: var(--color-blue);
		    right: 0;
		    top: 0;
		}
		&:after{
			content: '';
			display: block;
		    position: absolute;
		    width: 26vw;
		    height: 40px;
		    background: var(--color-blue);
		    left: 0;
		    bottom: 0;
		}

		.vc_inner.vc_row-fluid{
			max-width: 600px;
			margin: auto;

			.wpb_single_image.vc_align_center{
				margin: 0;

				img{
					max-height: 300px;
					width: auto;

					@media (--phone){
						max-height: 200px;
						margin-bottom: 50px;
					}
				}
			}
		}

		
	}
}

.home-hire{
	margin: 40px 0;

	@media (--phone){
		.wpb_column:first-child{
			height: 150px;
		}
	}

	.vc_column-inner{
		@media (--not_phone){
			padding: 40px !important;
		}
    	color: white;

    	.custom-btn a.vc_general{
    		border: none;
    		background: white !important;
    	}
	}
}


.contact-title{
	margin: 50px 0;

	.custom-title{
		margin-bottom: 15px !important;
	}
	&>.wpb_column{
		background: white;
	}
	&:before{
		content: '';
	    position: absolute;
	    width: 50%;
	    height: 90%;
	    background: var(--color-blue);
	    top: 50%;
	    display: block !important;
	    left: 0;
	    transform: translateY(-50%);
	}
	&:after{
		content: '';
	    position: absolute;
	    display: block !important;
	    width: 10%;
	    height: 1px;
	    background: var(--color-blue);
	    top: 70%;

	    @media (--phone){
	    	top: 55%;
	    }
	}
	.wpb_text_column.wpb_content_element{
		margin-bottom: 0;
	}

}
.contact-form{
	h2{
		margin-bottom: 0;
		margin-top: 0;
	}
	
	.contact-split{
		@media (--desktop){
			display: flex;

			span{
				&:first-child{
					width: 24%;
					margin-right: 1%;
				}
				&:nth-child(2){
					width: 24%;
					margin-right: 1%;
				}
				&:last-child{
					width: 50%;
				}
			}
		}
		input{
			margin-bottom: 15px;
			height: 50px;
		}

	}
	textarea{
			padding: 10px;
		}

	p{
		margin-bottom: 10px;
		color: var(--color-blue);
	}

	input[type=submit] {
	    width: 25%;
	    height: 50px;
	    font-size: 16px;
    	/*font-weight: 700;*/
    	background: var(--color-blue);
    	color: white;
    	border-radius: 10px;
    	font-family: 'font-r';

    	@media (--portable){
			width: 100%;
    	}
	}
	textarea.wpcf7-form-control.wpcf7-textarea{
		height: 95px;
	}
	.vc_col-sm-6:last-child .vc_column-inner>.wpb_wrapper {
		@media (--desktop){
	    max-width: 585px;
	    padding-left: 50px;
	}
	}

}

.home-contact>.vc_col-sm-6:first-child .vc_column-inner {
    @media (--not_desktop){
	    height: 200px;
	}
}

@media (--not_desktop){
	.contact-form form.wpcf7-form{
		display: flex;
		flex-direction: column;

		p:nth-last-child(4){
			order: 2;
		}
		p:nth-last-child(2){
			order: 3;
		}
		div:nth-last-child{
			order: 4;
		}

	}
}

.home-video h3.vc_custom_heading {
    margin-bottom: 35px;
}