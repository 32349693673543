.bg-cover{
	height: 35vw;

	@media (--not_phone){
			min-height: 550px;
		}
	&:before{
		content: '';
	    position: absolute;
	    height: 100%;
	    width: 100%;
	    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0));
	}
}

.about-cover{
	&>.wpb_column{
		position: absolute;
		bottom: 0;
	}
	.wpb_text_column.wpb_content_element{
		background-size: contain;
    	background-repeat: no-repeat;
    	width: fit-content;

    	@media (--not_phone){
    		padding: 45px 100px 50px 30px;
    	}

    	@media (--phone){
    		padding: 7px 3px;
    	}

    	p{
			color: white;
			margin-bottom: 0px;
			@media (--not_phone){
				font-size: 1.6em;
			}

			@media (--phone){
				padding-left: 15px;
			}
		}
		h2{
			color: white;
			@media (--not_phone){
				font-size: 35px;
			}

			@media (--phone){
				padding-left: 15px;
				margin-top: 0;
				font-size: 25px;
			}
		}
	}
}


.about-about{
	&>.wpb_column{
		&:first-child{
			text-align: right;
			
			@media (--not_phone){
				h2{
					text-align: right !important;
				}
				.vc_separator{
					margin-right: 0;
				}
			}

			@media (--phone){
				h2{
					text-align: center !important;
				}
				.wpb_text_column{
					text-align: center;
				}
			}
		}
		&:last-child{
			text-align: justify;

			@media (--not_phone){
				padding-top: 70px;
			}
		}

	}

}

.vision-mission{
	color: white;
	margin-bottom: 50px;

	&>.vc_row>.wpb_column.vc_column_container{
		padding: 15px;

		@media(--phone){
			.wpb_single_image{
				margin-bottom: 10px;
			}
			h3{
				text-align: center !important;
			}
		}

		img{
			max-width: 80px;
		}
	}
}

.home-icon.about-icon{
	padding-bottom: 30px;
	img{
		max-width: 100px;
		margin-bottom: 20px;
	}
}

.history{
	padding-top: 30px;
	padding-bottom: 30px;
	&:before{
		content: "";
	    display: block;
	    position: absolute;
	    width: 26vw;
	    height: 40px;
	    background: #0392d9;
        right: 0;
		top: 0;
	}
	.hist-row1{
		&>.wpb_column:first-child{
			@media (--not_phone){
				padding-left: 8%;
			}
		
		.vc_figure{
			padding-right: 20%;
			position: relative;

			@media (--phone){
				padding-right: 0;
				text-align: center;
			}

			&:before{
				content: '';
			    position: absolute;
			    height: 40px;
			    width: 70%;
			    background: var(--color-blue);
			    bottom: -15px;
			    left: 0;

			    @media(--phone){
			    	left: 50%;
			    	transform: translateX(-50%);
			    }
			}

			img{
				position: relative;
			}
		}
			p{
				@media (--phone){
					text-align: center;
				}
			}
		}
	}

	.hist-row2{
		.vc_figure{
			position: relative;

			&:before{
				content: '';
				position: absolute;
				height: 40px;
				width: 100%;
				right: -20px;
				bottom: -15px;
				background: var(--color-blue);
			}

			img{
				position: relative;
			}
		}
	}

	.contact-title:after{
		top: 85%;
	}
}

.achieve{
	.wpb_text_column{
		width: 20%;
		padding-bottom: 20px;

		@media (--phone){
			width: 100%;
			padding-bottom: 0;
		}

		@media (--not_phone){
			float: left;
		}

		.wpb_wrapper{
			padding: 5px;
		}
		h4 {
	    -webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 60%);
	    clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 60%);
	    background: var(--color-blue);
	    padding: 40px;
	    font-size: 40px;
	    color: white;
		}

		&:nth-child(2n){
			h4{
				background: white;
				color: var(--color-blue);
			}
		}
	}
}





.about-slogan {
	.big-title{
		margin-bottom: 5px;
		margin-top: 20px;
	}

	
}

.custom-title3{
	position: relative;
	padding-bottom: 20px;

	&:after{
		content: '';
	    position: absolute;
	    width: 100px;
	    height: 3px;
	    background: #28aed2;
	    bottom: 0;
	    left: 50%;
	    transform: translateX(-50%);
	}
}

