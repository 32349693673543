.title-cover{
	&>.wpb_column{
		position: absolute;
		bottom: 0;
	}
	.wpb_text_column.wpb_content_element{
		background-size: cover;
    	background-repeat: no-repeat;
    	width: fit-content;
    	background-position: right;

    	@media (--not_phone){
    		padding: 10px 120px 20px 100px;
    	}

    	@media (--phone){
    		padding: 6px 80px 18px 15px;
    	}

    	p{
			color: white;
			margin-bottom: 0px;
			@media (--not_phone){
				font-size: 1.6em;
			}
		}
		h2{
			color: white;
			@media (--not_phone){
				font-size: 35px;
			}
		}
	}
}

.archive{
	.bg-cover{
		background: url(https://project.inspiren.com.my/leanlee/wp-content/uploads/2019/05/product-cover.jpg) !important;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center !important;
		margin-bottom: 50px;

		.wpb_text_column{
			background-image: url(https://project.inspiren.com.my/leanlee/wp-content/uploads/2019/05/title-bg3.png) !important;
			margin-bottom: 35px;

			h2{
				margin-bottom: 0;
			}
		}
	}
	h2.cat-title.custom-title{
		text-align: center;
		color: var(--color-blue);
	}
	h3.archive-title {
	    font-size: 25px;
	    color: var(--color-blue);
	}
	#primary{
		li.product.type-product{
			.product-img-container{
				border: 2px solid var(--color-blue);
				height: 280px;
				display: flex;
				align-items: center;
				overflow: hidden;

				@media (--phone){
					height: 200px;
				}

					img{
						max-height: 280px;
						width: auto;
						margin: auto;
					}
			}

			
		}
	}
}

.woocommerce{
	.content-wrapper>.row{
		display: flex;

		@media (--phone){
			flex-direction: column;
		}
		
		#primary{
			order: 2;
		}
		#secondary{
			.widget_product_categories {
				@media (--not_phone){
				    margin-top: 60px; 
				    padding-right: 15%;
				}

				li.cat-item {
				    font-size: 20px;
				    font-weight: 600;
				    border-bottom: 1px solid var(--color-blue);

				    &.current-cat a{
				    	color: var(--color-blue); 
				    }
				    &:last-child{
				    	border-bottom: 0;
				    }
				}
			}
		}

	}
	div#categoryaccordionpanel{
		display: none;
	}

	&.term-27,&.term-28{
		.glean-cover{
			background: url(https://project.inspiren.com.my/leanlee/wp-content/uploads/2019/05/glean-cover.jpg) !important;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center !important;
			margin-bottom: 50px;
		}
		div#categoryaccordionpanel{
			display: block;

			.price-item{
				background: none;
				border-bottom: 1px solid var(--color-blue);

				a{
					font-size: 20px;
					font-family: 'font-b';
					/*pointer-events: none;*/
				}
				&.current a{
					color: var(--color-blue);
				}
				
			}
			.ik-product-name {
				height: fit-content !important;
				border-bottom: 1px solid var(--color-blue);
				padding-bottom: 10px;

				a{
					font-size: 16px;
					font-family: 'font-r';
					line-height: 18px;
					pointer-events: none;
				}
			}
			p.product.woocommerce.add_to_cart_inline,
			.ik-product-image{
				display: none;
			}
		}
		.widget_product_categories{
			display: none;
		}
		li.product.type-product{
			width: 100% !important;
			margin: 0 !important;
			pointer-events: none;

			.product-img-container{
				display:none !important;
			}
			h2.woocommerce-loop-product__title{
				font-size: 20px;
				text-align: left;
				color: var(--color-blue);
			}
		}
		h2.cat-title.custom-title{
			display: none;
		}
	}
}
.woocommerce.woocommerce-page ul.products li.product .price,
li.cat-item.cat-item-27,
li.cat-item.cat-item-28,
.woocommerce ul.products li.product .button,
nav.woocommerce-breadcrumb,
p.woocommerce-result-count,
form.woocommerce-ordering,
#secondary h3.widget-title{
	display: none;
}