   /* ####################################################################################

                                    OVERALL

/* ####################################################################################*/
body{
	h1,h2,h3,h4,h5,h6{
		font-family: 'font-b';
	}
	p{
		font-family: 'font-r';
		line-height: 24px;
	}
}
.header-clone{
	display: none;
}

#content .container.content-wrapper{
	padding-top: 0;
}
img.site-logo {
    max-width: 75px;
    background: white;
}

header#masthead.site-header {
    background: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0));
}
header#masthead{
	padding: 5px;
	background-color: rgba(51,51,51,0.6);
	position: fixed !important;

	li.menu-item{
		a{
			font-size: 16px;
		}
		@media (--desktop){
			/*padding: 0 6.7%;	*/
		}
	}

	li.current-menu-item a{
		color: var(--color-red);
		font-weight: 600;
	}
}

nav#mainnav{
	width: 100%;
}



#sidebar-footer{
	padding: 50px 0 20px 0;

	@media (--not_desktop){
		padding: 30px 0 0px
	}

.sidebar-column {

	@media (--not_desktop){
		text-align: center;
	}

	&:first-child{
		@media (--desktop){
			width: 40%;
		}
		aside{
			overflow: hidden;
			padding-top: 0;

			@media (--phone){
				margin-bottom: 25px;
			}
			.textwidget{

				@media (--not-phone){
					display: flex;
				}

				.col-md-9{
					position: absolute;
					right: 0;
					bottom: 3px;

					@media (--phone) {
						position: relative;
					}
				}
			}
			.col-md-3{
				padding: 5px !important;
			}
		}
		h1{
			margin-bottom: 0;
			font-size: 30px;
			color: white;
		}
		h2{
			font-size: 20px;
			color: white;
			margin-top: 0;
			margin-bottom: 0;
			.company-num{
				font-size: 13px;
			}
		}
		img{
			background: white;

			@media (--phone){
				max-width: 80px;
			}
		}
    }

    &:nth-child(2){
    	@media (--desktop){
    		width: 40%;
    	}
		.textwidget h3{
			font-size: 23px;
		    font-weight: 700;
		    margin: 0;
		}

		@media(--not_desktop){
			.contact-table{
				display: flex;
				justify-content: center;

				table{
					width: fit-content;
				}
			}
		}		
		td {
		    border: none;
		    text-align: left;
		    /*vertical-align: text-bottom;*/
			font-family: 'font-r';
			padding-bottom: 30px;

			@media (--phone){
				padding-bottom: 10px;

				br{
					display: none;
				}
			}
		    &:first-child{
				font-size: 0;

				i{
					font-size: 16px;
				}
			}
		}
    }
    &:last-child{
    	@media (--desktop){
			width: 20%;
	   		float: right;
	   		li{
				text-align: right;
			}
		}
		
    }
    h3.widget-title{
    	padding-bottom: 10px;
    	color: var(--color-blue);
    	font-weight: 700;
    }

}
}

.content-area .hentry:first-child {
    padding: 0;
}


footer#colophon{
	background: rgba(51,51,51,0.3);

	.site-info.container{
		text-align: center;
		padding: 5px 0;

	}
}

@media (--not_phone){
a.hoverInspiren{
	float: none;
}
}

.menu-item.current-menu-item {
    border: 1px solid var(--color-blue);
    color: var(--color-blue);

    @media (--not_desktop){
    	border: none;
    }
}